import { Component } from "react";

class Statistics extends Component {
    state = {
        totalStaked: null,
        totalStakedUnits: null,
        averageDaysStaked: null,
        totalTickets1: 0,
        totalTickets2: 0
    }

    constructor(props) {
        super(props);
        this.toggleVisibility = this.toggleVisibility.bind(this);
    }

    toggleVisibility(e) {
        const text = e.currentTarget.parentNode.querySelector(".faq-text");
        const icon = e.currentTarget.querySelector("i");

        if (!text.style.display || text.style.display == "none") {
            text.style.display = "block";
            e.currentTarget.style.color = "#ffaa00";
            icon.classList.remove("fa-angle-down");
            icon.classList.add("fa-angle-up");
        } else {
            text.style.display = "none";
            e.currentTarget.style.color = "white";
            icon.classList.add("fa-angle-down");
            icon.classList.remove("fa-angle-up");
        }
    }

    render() 
    {
        return (
            <div id="faq">
                <h2> Virtual Revolution Staking F.A.Q. </h2>
                <ul>
                    <li>
                        <div className="faq-title" onClick={this.toggleVisibility}> What is Virtual Revolution Staking? <i className="fa fa-angle-down"></i> </div>
                        <div className="faq-text"> 
                            <p> Virtual Revolution Staking is our way to generously reward our committed Victoria VR Supporters who want to be part of the Virtual Revolution!  </p>
                            <p> VR Stakers will be rewarded in multiple ways: </p>
                            <ul>
                                <li> Monthly Rewards </li>
                                <li> Dynamic Reward Bonus </li>
                                <li> VR Land whitelisting (VR Lands Tickets) </li>
                                <li> Mystery Airdrops </li>
                                <li> DAO participation </li>
                            </ul>
                            <p> Virtual Revolution Staking will fairly redistribute rewards to those users who trust and support our project. </p>
                        </div>
                    </li>
                    <li>
                        <div className="faq-title" onClick={this.toggleVisibility}> What are the Virtual Revolution Staking Requirements? <i className="fa fa-angle-down"></i> </div>
                        <div className="faq-text">
                            <ul>
                                <li> Minimal Staked Amount: 168 VR tokens </li>
                                <li> Staking period: 30 days to 3650 days </li>
                                <li> VR tokens Rewards: Can be claimed monthly </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <div className="faq-title" onClick={this.toggleVisibility}> How does Virtual Revolution Staking work? <i className="fa fa-angle-down"></i> </div>
                        <div className="faq-text"> 
                            <p> Virtual Revolution Staking will give users VR Shares. Users are rewarded depending on these VR Shares. They are the key to the benefits and rewards you can receive. </p>
                            <p> The number of VR Shares depends on your VR Staked Amount and VR Shares Bonus </p>
                            <p> Number of VR Shares = VR Staked Amount * (1 + VR Shares Bonus) </p>
                            <p> The VR Shares Bonus is based on Staking Period in days and the VR Staked Amount. </p>
                            <p> Bonus VR Share % per year: 800% </p>
                            <p> Bonus VR Share % per 1M VR: 6% </p>
                            <p> VR Shares Bonus = Staking Period in days * (800% / 365 days) + VR Staked * (6% / 1,000,000 VR) </p>
                            <p> Staker’s VR rewards depend on Staker’s share in the VR Pool. </p>
                            <p>
                                VR Pool = Sum of all Numbers of VR Shares<br></br>
                                Staker’s share in the VR Pool = Number of VR Shares / Total VR Shares in the VR Pool * 100%
                            </p>
                            <p> Estimated Annual Rewards = (Number of Staker’s VR Shares / Total VR Shares in the VR Pool * Average Reward Per Year) / VR Staked Amount </p>
                            <p> Estimated monthly rewards = (Number of Staker’s VR Shares / Total VR Shares in the VR Pool * Average Reward Per Year) / 12 </p>
                        </div>
                    </li>

                    <li>
                        <div className="faq-title" onClick={this.toggleVisibility}> VR Tokens Rewards <i className="fa fa-angle-down"></i> </div>
                        <div className="faq-text"> 
                           <p> VR Token Rewards are precisely defined until the end of the vesting period for all VR tokens, excluding the VR Rewards Pool. After the game's release and the launch of the VR Rewards Pool, rewards will be determined by the number of active users and stakers. </p>
                           <p> For this period, a total of 792,381,914 VR Tokens will be distributed as rewards. These rewards are evenly allocated each month based on the circulating supply, excluding the Strategic Reserve. The annual reward average is calculated over the three-year period. </p> 
                           <p> Stakers are directly rewarded based on the number of active users. </p>
                           <p> The first VR reward was available for the claim on February 2, 2022, at 2 PM UTC. </p>
                           <p> Staking rewards can be claimed on the 2nd of every month at 2 PM UTC. </p>
                           <p> For a detailed breakdown of the exact monthly rewards, click <a href="https://victoria-vr.medium.com/virtual-revolution-staking-the-first-reward-80d92b073dba" target="_blank">here</a>.</p>
                        </div>
                    </li>

                    <li>
                        <div className="faq-title" onClick={this.toggleVisibility}> VR Lands Tickets <i className="fa fa-angle-down"></i> </div>
                        <div className="faq-text"> 
                            <p> VR Lands Tickets are used for VR Lands whitelisting. These VR Lands Tickets can only be used for the first sale of VR Lands, but also for all subsequent ones in the future. </p>
                            <ul>
                                <li> 1 VR Lands Ticket gives the user the opportunity to get whitelisted for 1 VR Land (all Tiers) every snapshot. <i className="fa fa-angle-down"></i> </li>
                                <li> 1 VR Lands Ticket = 61,888 VR Shares </li>
                            </ul>
                            
                            <p> There are several ways to get 61,888 VR Shares, for example: </p>
                            <ul>
                                
                                <li> 6,888 VR tokens staked for 1 year </li>
                                <li> 816 VR tokens staked for 10 years </li>
                           </ul>
                            
                            <p> The more VR Lands Tickets you have, the more likely you are to whitelist. After whitelisting, users will be able to tell us in advance what VR Land tier they want. </p>
                            
                            <p> The first round of VR Land Sales here will be 3 snapshots: </p>
                            <ul>
                                <li> Snapshot: 24th December 11:00 PM UTC (Christmas Day) – 1,688 VR Lands </li>
                                <li> Snapshot: 31st December 11:00 PM UTC (New Year) – 3,688 VR Lands </li>
                                <li> Snapshot: 22nd March 11:00 PM UTC – TBD </li>
                            </ul>
                            <p> 
                                Don’t forget, you can always visit our Gitbook at <a href="https://victoria-vr.gitbook.io/" target="_blank">https://victoria-vr.gitbook.io/</a> for detailed information on everything Victoria VR.
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className="faq-title" onClick={this.toggleVisibility}> Mystery Airdrops Tickets <i className="fa fa-angle-down"></i> </div>
                        <div className="faq-text"> 
                            <p> Mystery Airdrops Tickets are tickets to get a random Airdrop, which will be at the end of each month and at special events (Christmas Day, New Year, Chinese New Year). In mystery airdrops, you can find VR tokens, VR Lands, Mystery Boxes, or other rewards. </p>
                            <p> 1 Mystery Airdrop Ticket = 10,168 VR Shares </p>
                            <p> There are several ways to get 10,168 VR Shares, for example: </p>
                            <ul>
                                <li> 10,168 VR tokens staked for 1 year </li>
                                <li> 168 VR tokens staked for 10 years</li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <div className="faq-title" onClick={this.toggleVisibility}> Why are VR rewards so big? <i className="fa fa-angle-down"></i> </div>
                        <div className="faq-text"> 
                            <p> Because we want to generously reward the REAL Victoria VR Believers! Together, we will take over! </p>
                        </div>
                    </li>
                    <li>
                        <div className="faq-title" onClick={this.toggleVisibility}> How to unstake your VR Tokens? <i className="fa fa-angle-down"></i> </div>
                        <div className="faq-text"> 
                            <p> You are not able to unstake your staked VR tokens before the end of the staking period. </p>
                        </div>
                    </li>
                    <li>
                        <div className="faq-title" onClick={this.toggleVisibility}> Smart Contract & Audits <i className="fa fa-angle-down"></i> </div>
                        <div className="faq-text"> 
                            <p> The smart contract used for staking is audited by Armors Labs. As mentioned in the audits, no major or high-risk concerns were discovered: <a href="http://odex.vip/?sign=557ee7c3f9bf462d36f61ed9a04e4c6d" target="_blank"> http://odex.vip/?sign=557ee7c3f9bf462d36f61ed9a04e4c6d </a> </p>
                        </div>
                    </li>
                </ul>
                
                <div id="disclaimer">
                    Please note that the main objective of this simulator is to explain the working of our Virtual Revolution Staking mechanism. The simulation does not represent a solicitation for investment and must not be understood in that manner. This web page and any other material published on this website shall not represent investment advice, financial advice, trading advice, or any other type of advice, and you should not use any of the website's content as such. You alone accept the complete responsibility of considering the pros and dangers connected with utilizing any information or other material on this website before making any choices based on such information. You realize that the crypto market is typified by extreme volatility, and you should be aware of the actual risk of losing the totality of the cash you allocated in the crypto market. You should avoid utilizing the money you cannot afford to lose while acquiring cryptocurrencies and other digital tokens.
                </div>

            </div>
        )
    }
}

export default Statistics;